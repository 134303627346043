import axios from "axios";
import { CONFIG } from './config';
import { toast } from 'react-toastify';

export const methods = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
};

export const createApiClient = (customConfig = {}) => {
    const defaultConfig = {
        baseURL: CONFIG.api_base_url + "api/v1/",
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true,
        tokenKey: "access_token",
        onAuthError: (error) => {
            toast.dismiss()
            toast.error('Session expired. Please login again.', {
                  autoClose: 1500,
                  onClose: ()=>{
                    localStorage.clear()
                    window.sessionExpDate = undefined
                    window.location.href = `/login?redirectUri=${window.location.href}`;
                  }
            });
        },
        onError: (error) => {
            if (error.response.status !== 401) {
                if (error.response?.data?.error?.debug_msg !== undefined && error.response?.data?.error?.debug_msg !== "") {
                    toast.error(error.response.data.error.debug_msg);
                } else if (error.response?.data?.error?.msg !== undefined) {
                    toast.error(error.response.data.error.msg);
                } else if (error.message !== undefined) {
                    toast.error(error.message);
                }
            }
        },
        onLogout: async () => {
            await request(`user-mgmt/user/logout`, methods.DELETE);
        }
    };

    // Merge custom config with default config
    const config = {
        ...defaultConfig,
        ...customConfig,
        headers: {
            ...defaultConfig.headers,
            ...customConfig.headers
        }
    };

    // Create axios instance with merged config
    const apiClient = axios.create({
        baseURL: config.baseURL,
        headers: config.headers,
        withCredentials: config.withCredentials
    });

    // Request interceptor for auth token
    apiClient.interceptors.request.use((reqConfig) => {
        const token = localStorage.getItem(config.tokenKey);
        if (token) {
            reqConfig.headers.Authorization = `Bearer ${token}`;
        }
        return reqConfig;
    });

    // Response interceptor for error handling
    apiClient.interceptors.response.use(
        (response) => response,
        async (error) => {
            console.log(error, error.message, error.response?.data?.error?.debug_msg, "--error");

            // Handle general errors
            config.onError(error);

            // Handle authentication errors
            if (error.response?.status === 401){ // || error.response?.status === 403) {
                await config.onLogout();
                config.onAuthError(error);
            }

            return Promise.reject(error);
        }
    );

    // Request function
    const request = async (url, method, data = {}, additionalConfig = {}) => {
        try {
            let result;
            const requestConfig = {
                ...additionalConfig,
                headers: {
                    ...apiClient.defaults.headers,
                    ...additionalConfig.headers
                }
            };

            switch (method) {
                case methods.GET:
                    result = await apiClient.get(url);
                    break;
                case methods.POST:
                    result = await apiClient.post(url, data, requestConfig);
                    break;
                case methods.PUT:
                    result = await apiClient.put(url, data, requestConfig);
                    break;
                case methods.PATCH:
                    result = await apiClient.patch(url, data, requestConfig);
                    break;
                case methods.DELETE:
                    result = await apiClient.delete(url);
                    break;
                default:
                    throw new Error("Invalid HTTP method");
            }
            window.sessionExpDate = result.headers['exp-date']

            return [null, result.data];
        } catch (error) {
            const errorDto = {
                status: error?.response?.status,
                statusText: error?.response?.statusText,
                data: error?.response?.data,
            };
            return [errorDto];
        }
    };

    return {
        client: apiClient,
        request
    };
};

export const { client: clientAPI, request } = createApiClient();

const analyticStudioConfig = {
    baseURL: CONFIG.analytic_studio + "/api/as/"
}

export const { client: analyticStudioAPI, request: requestAnalyticStudio } = createApiClient(analyticStudioConfig);